import React, { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet-easyprint';

const CaptureMapButton: React.FC = () => {
    const map = useMap();

    useEffect(() => {
        // @ts-ignore
        const easyPrint = L.easyPrint({
            title: 'Download Map',
            position: 'topleft',
            filename: 'map',
            sizeModes: ['A4Portrait', 'A4Landscape'],
            exportOnly: true,
        }).addTo(map);

        return () => {
            map.removeControl(easyPrint);
        };
    }, [map]);

    return null;
};

export default CaptureMapButton;
