import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {useMap} from "react-leaflet";
import L from "leaflet";
import {scaleLinear, scaleThreshold} from "d3-scale";
import {debounce} from "lodash";
import {interpolateHsl} from "d3-interpolate";

const Layers: React.FC<{
    visibleProps: any;
    selectedOmraade: string;
    selectedFeature: string;
    selectedLag: string;
    selectedKey: string;
    colorRange: { startColor: string; middleColor: string; endColor: string };
    selectedAmountOfColors: number;
    BASE_URL: string;
    defaults: any;
    setThresholds: any;
    setColors: any;
    headers: any;
    selectedIntervalType: string;
    config: any;
    setIsFetching: any;
    setLoadingTime: any;
}> = ({
        visibleProps,
        selectedOmraade,
        selectedFeature,
        selectedLag,
        selectedKey,
        colorRange,
        selectedAmountOfColors,
        BASE_URL,
        defaults,
        setThresholds,
        setColors,
        headers,
        selectedIntervalType,
        config,
        setIsFetching,
                        setLoadingTime,
      }) => {

    const map = useMap();
    const geoJsonLayer = useRef<L.GeoJSON | null>(null);
    const [dataRange, setDataRange] = useState<[number, number] | null>(null); // State to store data range
    const [estimatedLoadingTime, setEstimatedLoadingTime] = useState<number>(1000); // Store dynamic estimate
    const actualLoadingTimes = useRef<number[]>([]); // Store actual loading times

    // Function to calculate the moving average of actual loading times
    const calculateMovingAverage = (newTime: number) => {
        actualLoadingTimes.current.push(newTime);

        // Limit the number of stored times for a smoother average (e.g., last 5 fetches)
        if (actualLoadingTimes.current.length > 5) {
            actualLoadingTimes.current.shift(); // Remove oldest time
        }

        // Calculate average time
        return actualLoadingTimes.current.reduce((a, b) => a + b, 0) / actualLoadingTimes.current.length;
    };

    // Function to estimate the loading time based on zoom, bounds, and lag type
    const estimateLoadingTime = (lag: string, bounds: L.LatLngBounds, zoom: number): number => {
        let baseTime = estimatedLoadingTime; // Start with the current estimated time

        // Adjust base time based on lag type
        if (lag === 'kommune') {
            baseTime += 1000; // Add extra time for "kommune"
        } else if (lag === 'sogn') {
            baseTime += 1500; // Add more time for "sogn"
        } else if (lag === 'merged') {
            baseTime += 500; // Less time for "merged"
        }

        // Calculate area based on bounds (as a rough estimate)
        const area = Math.abs(bounds.getNorthEast().lat - bounds.getSouthWest().lat) * Math.abs(bounds.getNorthEast().lng - bounds.getSouthWest().lng);
        const areaFactor = Math.max(1, area / 10); // Normalize the area size

        // Adjust time based on zoom and area size
        const zoomFactor = zoom <= 10 ? 2 : zoom <= 14 ? 1.5 : 1;
        return baseTime * areaFactor * zoomFactor;
    };

    // Generate an array of color stops based on the selected number of colors
    const generateColorRange = useCallback(() => {
        const colorScale = scaleLinear<string>()
            .domain([0, 0.5, 1]) // Three points for start, middle, and end
            .range([colorRange.startColor, colorRange.middleColor, colorRange.endColor])
            .interpolate(interpolateHsl); // HSL interpolation for smoother transitions

        const generatedColors = Array.from({ length: selectedAmountOfColors }, (_, i) =>
            colorScale(i / (selectedAmountOfColors - 1))
        );

        setColors(generatedColors); // Save colors for use in the legend
        return generatedColors;
    }, [colorRange, selectedAmountOfColors, setColors]);

    const generateThresholds = useCallback(
        (minValue: number, maxValue: number) => {
            const step = (maxValue - minValue) / selectedAmountOfColors;
            const generatedThresholds = Array.from({ length: selectedAmountOfColors }, (_, i) =>
                minValue + step * i
            );

            setThresholds(generatedThresholds); // Save thresholds for the legend
            return generatedThresholds;
        },
        [selectedAmountOfColors, setThresholds]
    );

    const getMinMax = (data: any, targetFeature: any, targetKey: any, targetLag: any) => {

        let changeLag = targetLag;

        if (targetLag == 'auto') {
            if (config.zoom <= 8) {
                changeLag = 'kommune'
            } else if (config.zoom <= 10) {
                changeLag = 'sogn'
            } else {
                changeLag = 'merged'
            }
        }

        // Find the feature object that matches the target feature
        const featureObj = data[0].features.find((feature: any) => feature.feature === targetFeature);

        if (!featureObj) {
            return null; // If the feature is not found, return null
        }

        // Find the lag object that matches the target key and lag
        const lagObj = featureObj.lags.find((lag: any) =>
            lag.key === targetKey && lag.lag === changeLag
        );

        if (!lagObj) {
            return null; // If the lag object is not found, return null
        }

        // Return the min and max values
        return [lagObj.min, lagObj.max];
    }

    const applyStyle = useCallback(
        (feature: any) => {
            const properties = feature.properties;
            const value = properties?.value ? parseFloat(properties.value) : 0;

            if (!dataRange) {
                return { fillColor: '#00000000', weight: 1, opacity: 1, color: 'white', fillOpacity: 0.7 }
            }

            // @ts-ignore
            const [minValue, maxValue] = selectedIntervalType == 'local' ? dataRange : getMinMax(headers, selectedFeature, selectedKey, selectedLag);
            const thresholds = generateThresholds(minValue, maxValue);
            const colors = generateColorRange();

            const colorScale = scaleThreshold<number, string>()
                .domain(thresholds.slice(1)) // Slice to match colors and thresholds
                .range(colors);

            const fillColor = colorScale(value) || '#00000000';

            return {
                fillColor,
                weight: 1,
                opacity: 1,
                color: 'white',
                fillOpacity: 0.7,
            };
        },
        [generateColorRange, generateThresholds, dataRange, selectedAmountOfColors, selectedIntervalType]
    );

    const calculateDataRange = useCallback((data: any) => {
        const values = data.features
            .map((feature: any) => parseFloat(feature.properties.value))
            .filter((value: number) => !isNaN(value));

        if (values.length > 0) {
            const min = Math.min(...values);
            const max = Math.max(...values);
            setDataRange([min, max]);
        }
    }, []);

    const updateLayerStyles = useCallback((next?: any) => {
        if (geoJsonLayer.current) {
            geoJsonLayer.current.eachLayer((layer) => {
                if (layer instanceof L.Polygon) {
                    const feature = (layer as any).feature;
                    layer.setStyle(applyStyle(feature));
                }
            });
        }
        if (next) next();
    }, [applyStyle]);

    const formatNumber = (num: number) => {
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(num);
    };

    const correctText = (text: any) => {
        return text
            .replace(/Ã¥/g, 'å')
            .replace(/Ã…/g, 'Å')
            .replace(/Ã¦/g, 'æ')
            .replace(/Ã†/g, 'Æ')
            .replace(/Ã¸/g, 'ø')
            .replace(/Ã˜/g, 'Ø');
    }

    // Click handler for layer click events
    const onLayerClick = useCallback((e: L.LeafletMouseEvent) => {
        const feature = (e.target as any).feature;
        const properties = feature.properties;

        console.log(properties)

        let desc = "";

        switch (properties.lag) {
            case 'sogn':
                desc = `${correctText(properties.sognenavn)}`
                break;
            case 'merged':
                desc = `${properties.area}`
                break;
            case 'kommune':
                desc = `${correctText(properties.komnavn)}`
                break;
        }



        // Create a popup with the relevant feature information
        const popup = L.popup()
            .setLatLng(e.latlng) // Set the position of the popup at the clicked point
            .setContent(
                `<div>
                    <strong>Område:</strong> ${desc}<br/>
                    <strong>Værdi:</strong> ${formatNumber(properties.value)}
                </div>`
            )
            .openOn(map); // Attach the popup to the map
    }, [map]);

    // Memoized fetchGeoJsonData to avoid re-creation on every render
    const fetchGeoJsonData = useMemo(
        () =>
            debounce(async (lag?: any) => {

                console.log(lag, selectedFeature, selectedOmraade); // Logs updated values

                if (!lag || !!lag.type) {
                    lag = selectedLag;
                }

                if (selectedKey === '') return;

                const bounds = map.getBounds();
                const bbox = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()]
                    .map((coord) => coord.toFixed(6))
                    .join(',');

                const zoom = map.getZoom();
                let urlParams = `&omraade=${selectedOmraade}&feature=${selectedFeature}&key=${selectedKey}&zoom=${zoom}&bbox=${bbox}`;
                let url = `${BASE_URL}?lag=${lag}${urlParams}`;

                if (lag == 'auto') {
                    if (zoom <= 8) {
                        url = `${BASE_URL}?lag=kommune${urlParams}`;
                    } else if (zoom <= 10) {
                        url = `${BASE_URL}?lag=sogn${urlParams}`;
                    } else {
                        url = `${BASE_URL}?lag=merged${urlParams}`;
                    }
                }

                try {

                    const estimatedTime = estimateLoadingTime(lag, bounds, zoom); // Estimate fetch time
                    const startTime = Date.now();

                    setLoadingTime(estimatedTime)
                    setIsFetching(true);

                    const response = await fetch(url);

                    if (!response.ok) {
                        setIsFetching(false);
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();

                    if (!data.features) {
                        setIsFetching(false);
                        throw new Error('Invalid data format');
                    }

                    calculateDataRange(data);

                    if (geoJsonLayer.current) {
                        geoJsonLayer.current.clearLayers();
                        geoJsonLayer.current.addData(data.features);
                    } else {
                        geoJsonLayer.current = L.geoJSON(data.features, {
                            style: applyStyle,
                            onEachFeature: (feature, layer) => {
                                layer.on('click', onLayerClick); // Attach click event to each feature
                            },
                        }).addTo(map);
                    }

                    updateLayerStyles(() => {
                        const endTime = Date.now();
                        const actualTime = endTime - startTime; // Calculate the actual time taken
                        const newEstimatedTime = calculateMovingAverage(actualTime); // Calculate new estimate based on actual time
                        setEstimatedLoadingTime(newEstimatedTime); // Update the estimated time for future requests
                        setIsFetching(false);
                        console.log("DONE")
                    })
                } catch (error) {
                    console.error('Error fetching GeoJSON data:', error);
                    setIsFetching(false);
                }
            }, 500),
        [
            map,
            selectedOmraade,   // Add selectedOmraade to the dependencies
            selectedFeature,    // Add selectedFeature to the dependencies
            selectedKey,
            selectedLag,
            visibleProps,
            applyStyle,
            updateLayerStyles,
            calculateDataRange,
        ]
    );

    useEffect(() => {
        fetchGeoJsonData(selectedLag);
    }, [selectedOmraade, selectedFeature, selectedLag, selectedKey]);

    useEffect(() => {
        updateLayerStyles();
    }, [colorRange, updateLayerStyles]);

    // Stable event handler attachment
    useEffect(() => {
        map.on('moveend', fetchGeoJsonData);
        map.on('zoomend', fetchGeoJsonData);
        return () => {
            map.off('moveend', fetchGeoJsonData);
            map.off('zoomend', fetchGeoJsonData);
        };
    }, [map, fetchGeoJsonData]);

    return null;
};

export default Layers;
