import React, { useEffect, useState, useRef } from 'react';
import { Spin, Progress } from 'antd';

interface LoadingSpinnerProps {
    milliseconds: number;
    isFetching: boolean; // This will indicate when the data fetching is done
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ milliseconds, isFetching }) => {
    const [loading, setLoading] = useState(false); // Controls whether the spinner is visible
    const [percent, setPercent] = useState(1); // Progress percentage
    const timerRef = useRef<ReturnType<typeof setInterval>>();

    // Function to start the loading progress
    const startLoading = () => {
        if (timerRef.current) {
            clearInterval(timerRef.current);
        }

        timerRef.current = setInterval(() => {
            setPercent((prevPercent) => {
                if (prevPercent < 90) {
                    return prevPercent + (90 / (milliseconds / 100)); // Progress to 90% evenly over the milliseconds
                } else {
                    clearInterval(timerRef.current); // Stop progress at 90%
                    return prevPercent;
                }
            });
        }, 100);
    };

    // Start loading progress when `isFetching` changes to true
    useEffect(() => {
        if (isFetching) {
            setLoading(true);
            setPercent(1); // Reset progress
            startLoading(); // Start progress animation
        }
    }, [isFetching]);

    // Once fetching is complete, move from 90% to 100% and then hide spinner
    useEffect(() => {
        if (!isFetching && percent >= 50) {
            setPercent(100);
            setTimeout(() => {
                setLoading(false);
                setPercent(1); // Reset percent for future loading cycles
            }, 500); // Hide spinner after a short delay
        }
    }, [isFetching, percent]);

    return (
        <>
            {loading && (
                <div className="loading-spinner-container">
                    <Spin size="default" percent={percent} />
                </div>
            )}
        </>
    );
};

export default LoadingSpinner;
