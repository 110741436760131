import React, { useEffect, useState, useCallback } from 'react';
import {Button, FloatButton, Radio, Select, Slider} from "antd";
import Title from "antd/lib/typography/Title";
import Paragraph from "antd/lib/typography/Paragraph";
import {CloseOutlined, MenuUnfoldOutlined, QuestionCircleOutlined} from "@ant-design/icons";
import ColorRangeSelector from "./ColorRangeSelector";

interface SidebarProps {
    labels: any;
    colors: any;
    thresholds: any;
    headers: any[];
    defaults: any;
    config: any;
    onSelect: (area: string, feature: string, lag: string, key: string) => void;
    handleZoomChange: any;
    handleCenterChange: any;
    handleColorChange: any;
    handleOpdelingChange: any;
    selectedIntervalType: any;
    handleIntervalType: any;
}

// Determine if keys are numerical
function isNumeric(keys: any) {
    return keys.every((key: any) => !isNaN(key));
}

const Sidebar: React.FC<SidebarProps> = ({
    labels,
    colors,
    thresholds,
    headers,
    defaults,
    config,
    onSelect,
    handleZoomChange,
    handleCenterChange,
    handleColorChange,
    handleOpdelingChange,
    selectedIntervalType,
    handleIntervalType
 }) => {
    const [isCardVisible, setIsCardVisible] = useState(true);
    const [isMenuVisible, setIsMenuVisible] = useState(true);
    const [activeTab, setActiveTab] = useState('indstillinger');

    const [selectedOmraade, setSelectedOmraade] = useState<string>(defaults.omraade);
    const [selectedFeature, setSelectedFeature] = useState<string>(defaults.feature);

    const [selectedOpdeling, setSelectedOpdeling] = useState<number>(8);

    const [selectedLag, setSelectedLag] = useState<string>(defaults.lags.default);
    const [selectedKey, setSelectedKey] = useState<string>(defaults.keys.default);

    const [availableLags, setAvailableLags] = useState<any[]>(defaults.lags.lags);
    const [availableKeys, setSelectedKeys] = useState<any[]>(defaults.keys.keys);

    const [bottomLabel, setBottomLabel] = useState<any>(labels.find((e: any) => e.omraade == selectedOmraade && e.feature == selectedFeature)?.label);

    const handleResize = () => {
        const smallScreen = window.innerWidth < 768;
        setIsMenuVisible(!smallScreen);
        setIsCardVisible(!smallScreen);
    };

    const capitalizeFirstLetter = (string: string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    };

    const formatNumber = (num: number) => {
        return new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(num);
    };

    // Memoize handleOmraadeFeatureChange to prevent re-rendering issues
    const handleOmraadeFeatureChange = useCallback((value: any) => {

        const [omraade, feature] = value.split('-');

        setSelectedOmraade(omraade);
        setSelectedFeature(feature);

        // Fetch the corresponding omraade data from the headers
        const selectedOmraadeData = headers.find((data: any) => data.omraade === omraade);

        if (selectedOmraadeData) {
            // Find the feature object under the selected omraade
            const selectedFeatureData = selectedOmraadeData.features.find((f: any) => f.feature === feature);

            if (selectedFeatureData) {
                // Set available lags for the selected feature
                setAvailableLags(selectedFeatureData.lags.map((lag: any) => lag.lag)); // Extract lags

                // @ts-ignore
                let keys = [...new Set(selectedFeatureData?.lags?.map((e: any) => e.key).sort())];

                if (keys.length) {
                    setSelectedKeys(keys);
                    setSelectedKey(keys[0]);
                } else {
                    setSelectedKeys([]); // No keys available
                }
            }
        }

        onSelect(omraade, feature, selectedLag, selectedKey)

        let findLabel = labels.find((e: any) => e.omraade == omraade && e.feature == feature)

        if (findLabel) {
            setBottomLabel(findLabel.label)
        } else {
            setBottomLabel("404");
        }


    }, [headers]);

    const handleLagChange = useCallback((value: any) => {
        setSelectedLag(value);
        onSelect(selectedOmraade, selectedFeature, value, selectedKey);
    }, [selectedOmraade, selectedFeature, selectedKey, onSelect]);

    const handleKeySliderChange = useCallback((value: any) => {
        setSelectedKey(value);
        onSelect(selectedOmraade, selectedFeature, selectedLag, value);
    }, [selectedOmraade, selectedFeature, selectedLag, onSelect]);

    const handleKeyDropdownChange = useCallback((value: any) => {
        setSelectedKey(value);
    }, []);

    const selectOpdeling = useCallback((value: any) => {
        handleOpdelingChange(value);
        setSelectedOpdeling(value);
    }, [handleOpdelingChange]);

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <div className="floating-card-container">

                <div style={{ paddingTop: 0 }}></div>

                {isMenuVisible && (
                    <>
                        <div>
                            <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                                <div style={{ display: 'flex', width: '100%' }}>
                                    <Button
                                        type={activeTab === 'indstillinger' ? 'primary' : 'text'}
                                        style={{ flex: 1, marginRight: 2 }}
                                        onClick={() => setActiveTab('indstillinger')}
                                    >
                                        Indstillinger
                                    </Button>
                                    <Button
                                        type={activeTab === 'om_projektet' ? 'default' : 'text'}
                                        style={{ flex: 1, marginLeft: 2 }}
                                        onClick={() => setActiveTab('om_projektet')}
                                    >
                                        Om projektet
                                    </Button>
                                </div>
                            </div>
                        </div>

                        {activeTab === 'indstillinger' && (
                            <div>

                                <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                                    <div style={{ padding: 8 }}>
                                        <Title level={4} style={{ marginTop: 0 }}>Atlas</Title>
                                        <Paragraph style={{ marginTop: 0 }}>
                                            Værktøj til at studere udviklingen i Danmark. Vælg område herunder:
                                        </Paragraph>

                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder="Vælg et område og funktion"
                                            value={`${selectedOmraade}-${selectedFeature}`} // Set default selected value
                                            onChange={(value) => handleOmraadeFeatureChange(value)}
                                        >
                                            {!!headers && headers.map(omraade => (
                                                <Select.OptGroup
                                                    label={capitalizeFirstLetter(omraade.omraade)} // Capitalize first letter of "omraade"
                                                    key={omraade.omraade}
                                                >
                                                    {omraade.features.map((feature: any) => (
                                                        <Select.Option
                                                            key={`${omraade.omraade}-${feature.feature}`}
                                                            value={`${omraade.omraade}-${feature.feature}`}
                                                        >
                                                            {typeof feature.feature === 'object'
                                                                ? capitalizeFirstLetter(JSON.stringify(feature.feature)) // Capitalize if object
                                                                : capitalizeFirstLetter(feature.feature) // Capitalize first letter of "feature"
                                                            }
                                                        </Select.Option>
                                                    ))}
                                                </Select.OptGroup>
                                            ))}
                                        </Select>
                                    </div>
                                </div>

                                {selectedFeature && (
                                    <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                                        <div style={{ padding: 8 }}>
                                            <Title level={4} style={{ marginTop: 0 }}>Lag</Title>
                                            <Radio.Group
                                                style={{ display: 'flex', width: '100%' }}
                                                value={selectedLag || "auto"}
                                                onChange={(e) => handleLagChange(e.target.value)}
                                            >
                                                {/* Always enabled "Auto" */}
                                                <Radio.Button style={{ flex: 1, textAlign: 'center' }} value="auto" key="auto">
                                                    Auto
                                                </Radio.Button>

                                                {/* "Kommune" */}
                                                <Radio.Button style={{ flex: 1, textAlign: 'center' }} value="kommune" key="kommune" disabled={!availableLags.includes("kommune")}>
                                                    Kommune
                                                </Radio.Button>

                                                {/* "Sogn" */}
                                                <Radio.Button style={{ flex: 1, textAlign: 'center' }} value="sogn" key="sogn" disabled={!availableLags.includes("sogn")}>
                                                    Sogn
                                                </Radio.Button>

                                                {/* "Merged" */}
                                                <Radio.Button style={{ flex: 1, textAlign: 'center' }} value="merged" key="merged" disabled={!availableLags.includes("merged")}>
                                                    Merged
                                                </Radio.Button>
                                            </Radio.Group>
                                        </div>
                                    </div>
                                )}

                                {selectedFeature && selectedLag && (
                                    <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                                        <div style={{ padding: 8 }}>
                                            <Title level={4} style={{ marginTop: 0 }}>Årstal</Title>
                                            <Select
                                                style={{ width: '100%' }}
                                                value={selectedKey}
                                                onChange={handleKeySliderChange}
                                                placeholder="Årstal"
                                            >
                                                {availableKeys.sort((a: any, b: any) => parseInt(b) - parseInt(a)).map((key: any) => (
                                                    <Select.Option key={key} value={key}>
                                                        {key}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </div>
                                )}

                                <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                                    <div style={{ padding: 8 }}>
                                        <Title level={4} style={{ marginTop: 0 }}>Interval</Title>
                                        <Select
                                            style={{ width: '100%' }}
                                            value={selectedOpdeling}
                                            onChange={selectOpdeling}
                                            placeholder="Interval"
                                        >
                                            {[2, 3, 4, 5, 6, 7, 8].map((value: number) => (
                                                <Select.Option key={value} value={value}>
                                                    {value}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </div>
                                </div>

                                <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                                    <div style={{ padding: 8 }}>
                                        <ColorRangeSelector parentHandleColorChange={handleColorChange}></ColorRangeSelector>
                                    </div>
                                </div>

                            </div>
                        )}

                        {activeTab === 'om_projektet' && (
                            <div>
                                <div className="floating-card" style={{ opacity: 0.9, marginBottom: 8, padding: 6 }}>
                                    <div style={{ padding: 8 }}>
                                        <Title level={4} style={{ marginTop: 0 }}>Atlas</Title>
                                        <Paragraph style={{ marginTop: 0 }}>
                                            { labels.find((e: any) => e.omraade == 'omprojektet' && e.feature == 'omprojektet')?.label }
                                        </Paragraph>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}

                {isCardVisible && (
                    <div>
                        <div className="floating-card" style={{ opacity: 0.9, minHeight: 80, position: 'fixed', bottom: 30, left: 110, zIndex: 1000, width: 'calc(100% - 130px)' }}>
                            <div style={{ padding: 8, marginLeft: 8, marginTop: 5 }}>
                                { bottomLabel }
                            </div>
                        </div>
                    </div>
                )}

                <FloatButton.Group shape="circle" style={{ insetInlineStart: 36 }}>
                    <FloatButton icon={<MenuUnfoldOutlined />} type="default" style={{ insetInlineEnd: 36 }} onClick={() => setIsMenuVisible(!isMenuVisible)}/>
                    <FloatButton icon={<QuestionCircleOutlined />} type="default" style={{ insetInlineStart: 36 }} onClick={() => setIsCardVisible(!isCardVisible)}/>
                </FloatButton.Group>

            </div>

            {isMenuVisible && thresholds.length > 0 && (
                <div className="legend">
                    <Title level={4} style={{ color: "rgba(0,0,0,0.8)", marginTop: 12 }}>Farve intervaller</Title>

                    <Select
                        style={{ width: '100%', marginBottom: 8 }}
                        defaultValue={selectedIntervalType}
                        value={selectedIntervalType}
                        onChange={(value) => handleIntervalType(value)}
                    >
                        <Select.Option key={"local"} value={"local"}>Lokal</Select.Option>
                        <Select.Option key={"global"} value={"global"}>National</Select.Option>
                    </Select>

                    {thresholds.map((threshold: any, index: any) => (
                        <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '4px' }}>
                            <div
                                style={{
                                    width: '20px',
                                    height: '20px',
                                    backgroundColor: colors[index],
                                    marginRight: '8px',
                                }}
                            />
                            <Paragraph style={{ color: "rgba(0,0,0,0.8)", margin: 0, fontWeight: 400 }}>
                                {`${formatNumber(threshold)} - ${thresholds[index + 1] ? formatNumber(thresholds[index + 1]) : 'Max'}`}
                            </Paragraph>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};

export default Sidebar;
